





















import { UnreadData } from '@/components/ChatsBar/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Btn: () => import('@/components/ChatsBar/List/UnreadNotifier/Btn.vue'),
  },
})
export default class UnreadNotifier extends Vue {
  @Prop({ type: Object, required: true }) private readonly data!: UnreadData
  @Prop({ type: Boolean }) private readonly forceShowTop?: boolean
}
